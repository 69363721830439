import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/ground-round-diabetis-melitus/1.jpeg';
import blogd2 from '../../assets/images/2024/ground-round-diabetis-melitus/2.jpeg';
import blogd3 from '../../assets/images/2024/ground-round-diabetis-melitus/3.jpeg';
import blogd4 from '../../assets/images/2024/ground-round-diabetis-melitus/4.jpeg';
import blogd5 from '../../assets/images/2024/ground-round-diabetis-melitus/5.jpeg';
import blogd6 from '../../assets/images/2024/ground-round-diabetis-melitus/6.jpeg';
import blogd7 from '../../assets/images/2024/ground-round-diabetis-melitus/7.jpeg';
import blogd8 from '../../assets/images/2024/ground-round-diabetis-melitus/8.jpeg';
import blogd9 from '../../assets/images/2024/ground-round-diabetis-melitus/9.jpeg';
import blogd10 from '../../assets/images/2024/ground-round-diabetis-melitus/10.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  const subs = [
    {
      SNO: '1',
      SPEAKERS: 'Dr. Oluwaroyimi Olopade',
      SESSION:
        'Introduction, epidemiology, pathophysiology, clinical features and management of diabetes mellitus',
    },
    {
      SNO: '2',
      SPEAKERS: '⁠Dr. Ifedayo Odeniyi',
      SESSION:
        'The economic burden and challenges of Diabetes mellitus in Nigeria',
    },
    {
      SNO: '3',
      SPEAKERS: 'Dr. Nelson Aliya',
      SESSION:
        'Global humanitarian intervention in Amelioration of resource challenged environment',
    },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.SNO}>
      <td>{element.SNO}</td>
      <td>{element.SPEAKERS}</td>
      <td>{element.SESSION}</td>
    </tr>
  ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LAGOS UNIVERSITY TEACHING HOSPITAL SPECIAL GRAND ROUND <br />
                  THEME: BURDEN AND CHALLENGES OF MANAGING DIABETES MELLITUS:
                  EXPLORING HUMANITARIAN INTERVENTION
                </h2>
                <Table fontSize='md' striped>
                  <thead>
                    <tr>
                      <th>SNO</th>
                      <th>SPEAKERS</th>
                      <th>SESSION</th>
                    </tr>
                  </thead>
                  <tbody>{subRows}</tbody>
                </Table>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Dr. Oluwarotimi Olopade, Consultant Endocrinologist and
                  diabetologist, LUTH provided a comprehensive overview of
                  Diabetes Mellitus during the special Grand round, his
                  presentation covered various crucial aspects including
                  Introduction, epidemiology, pathophysiology, diagnosis and
                  management of Diabetes Mellitus.
                  <br />
                  Dr. Oluwarotimi outlined the fundamental concepts of Diabetes
                  Mellitus emphasizing its significance as a global health
                  concerns. He delved into the epidemiological trends of
                  Diabetes Mellitus highlighting the prevalence of predicates
                  and diabetes in selected states in Nigeria.
                  <br />
                  He elucidated the underlying pathophysiological mechanisms
                  driving diabetes Mellitus , elucidating the intricate
                  interplay of genetic, environmental and lifestyle factors
                  contributing to its development.
                  <br />
                  Dr. Oluwarotimi emphasized the following treatment goals of
                  Diabetes Mellitus; individualized treatment, knowledgeable
                  about Diabetes Mellitus- education, knowledgeable about
                  treatment options, reduce risk of cardiovascular morbidity and
                  mortality and reduce complication risks and manage when
                  present.
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                The second speaker Dr. Ifedayo odeniyi, Associate professor of
                medicine and consultant endocrinologist, LUTH.
                <br />
                Dr. Ifedayo discussed the financial strain and difficulties
                associated with Diabetes mellitus in Nigeria, he highlighted the
                direct and indirect costs of diabetes mellitus in Nigeria.
                Additionally, the loss of productivity and disability added to
                the financial burden on individuals and families.
                <br />
                He outlined several challenges in providing effective diabetes
                care in Nigeria:
                <br />
                Lack of education and awareness about treatment, Insufficient
                government support and healthcare facilities, High costs of
                healthcare services and medicines, Shortage of trained
                healthcare workers and Cultural beliefs and socioeconomic
                factors affecting insulin use and monitoring.
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>
                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  He suggested several ways to tackle these challenges:
                  <br />
                  Educating the public through health campaigns, Improving
                  healthcare facilities and services, Making treatments more
                  affordable and accessible, Training healthcare workers and
                  Advocating for policy changes to remove barriers to care.
                  <br />
                  Dr. Ifedayo concluded with Challenges of diabetes care in
                  Nigeria are myriad <br />
                  •High economic burden of diabetes mellitus
                  <br />
                  •High catastrophic expenditure
                  <br />
                  <br />
                  Dr. Dr. Nelson Aluya concluded the presentation by discussing
                  the significance of global humanitarian interventions in
                  addressing resource-challenged environments, particularly
                  focusing on the global epidemic of Diabetes Mellitus and
                  strategies for its management and prevention. He emphasized
                  the importance of learning from developed countries to inform
                  interventions in developing nations. He underscored the
                  urgency of addressing Diabetes Mellitus as a global epidemic,
                  emphasizing the need for comprehensive strategies to manage
                  and prevent its adverse effects.
                  <br />
                  He outlined key management strategies for Diabetes Mellitus,
                  emphasizing the importance of lifestyle modifications,
                  including dietary changes, regular exercise, and weight
                  management. Additionally, Dr. Nelson highlighted the
                  significance of medication adherence, regular monitoring, and
                  access to quality healthcare services in mitigating the
                  disease burden.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                {/* <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd11} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd12} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd13} height={370} />
                </div>
              </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL SPECIAL GRAND ROUND  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
